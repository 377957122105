.container-mainBanner{
    width: 100%;
    height: 100vh;
    display: grid;
    position: relative;
    grid-template-columns: 3fr 7fr;
}
.intro-div{
    display: flex;
    padding:1rem;
    font-size: 3rem;
    gap: 0.5rem;
    &>span{
        width: 80%;
        text-align: start;
    }
}.container-mainBanner-animtaion{
    position: absolute;
    width: 55vw;
    height: 100vh;
    right: 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    &>div{
        background-color: white;
        mix-blend-mode: difference;
    }
}
