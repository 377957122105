.container-modal{
    width: 100%;
    height:100%;
    position: relative;
    padding: 0rem 2rem 0rem 2rem;
    overflow-x: hidden;
    overflow-y: scroll;
    &>h1{
        margin-top: 1rem;
        position: relative;
        font-size: 1.1rem;
        margin-left: 1rem;
        left: -50%;
        transform: translate(50%);
    }
    &>ol{
        gap:3rem;
        &>li{
            
        }
    }
}

.modal-line{
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 100%;
    height: 1px;
    background-color: rgb(126, 126, 126);
    
}.link-svg{
    color: rgb(108, 108, 108);
}.clip-svg{
    rotate: 135deg;
}.link-project{
    text-decoration: underline;
    color: rgb(134, 134, 255);
    // padding: 0.5rem;
    // margin-left: 1rem;
    cursor: pointer;
}.conatiner-modal-li{
    border:1px solid gray;
    border-radius: 6px;
    overflow: hidden;
    width: 100%;
    &>div:first-child{
        width: 100%;
        padding: 0.2rem;
        border-bottom: 1px solid gray;
    }
    &>div{
        gap:0.3rem;
        line-height: 1.3rem;
        &:nth-child(2){
            margin-left: 0.5rem;
        }
    }
    &>div>span:nth-child(2){
        
    }
}
.modal-skill-ol{
    list-style: disc;
}.modal-func-ol{
    list-style:disc;
    margin-left: 1rem;
}.modal-link-ol{
    margin-left: 0.5rem;
    padding: 0.5rem;
    &>li{
        padding: 0.3rem 0rem 0.3rem 0rem;
    }
    &>li>span{
        margin-right: 0.5rem;
        font-weight: bold;
    }
}.li-info{
    padding: 0.8rem 0.5rem 0.8rem 0.5rem;
    gap:1rem;
    &>div{
        display: grid;
        grid-template-columns: 1rem auto;
        align-items: start;
        margin-bottom: 0.3rem;
        &>span{
            font-weight: bold;
        }
        &>div{
            gap: 0.2rem;
        }
    }
}.conatiner-modal-li:nth-child(4){
    &>.li-info>div{
        display: flex;
    }
}
.modal-stack-svg{
    gap:0.2rem;
    padding: 0.2rem 0.4rem 0.2rem 0.4rem;
    border-radius: 6px;

    &>img{
        width: 1rem;
        height: 1rem;
    }
}
.modal-not-mine{
    text-decoration: line-through;
}




// --------------------------------------------------
//SCROLLBAR


  .container-modal::-webkit-scrollbar {
    width: 10px;
  }
  .container-modal::-webkit-scrollbar-thumb {
    // background-color:#323232;
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
  }
  .container-modal::-webkit-scrollbar-track {
    // background-color: rgb(0, 0, 0);
    border-radius: 10px;
    // box-shadow: inset 0px 0px 1px white;
  }