.frame-projectView-img{
    height: 100vh;
    left:0;
    margin-left: 0;
    position: relative;
    &>div{
        width: 100%;
        height: inherit;
        &>img{
            border-radius: 4px;
            border: 1.5px solid #b2b2b2;
        }
    }
}.projectView-img{
    position: absolute;
    left: 50%;
    top: 50%;
    width:95%;
    height: auto;
    display: flex;
    flex-direction: column;
    transform: translate(-50%,-50%);
    &>div{
        position: relative;
        width:inherit;
        height:inherit;
        
        &>img{
            width: 100%;
            height: 100%;
            border-radius: 6px;
            border: 2px solid #cdcdcd;
            cursor: pointer;
        }&>img:nth-child(2){
            transform: rotateX(180deg);
            position: absolute;
            left: 0;
            top: 100%;
            mask-image: linear-gradient(to bottom, rgb(255 255 255 / 0%) 0%, rgb(255 255 255 / 0%) 50%, rgb(255 255 255 / 30%) 100%);
        }
    }
    // &>img:nth-child(2){
    //     transform: rotateX(180deg);
    //     // opacity: 0.3;
    //     background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5)); /* 그라데이션으로 투명도 조절 */
    // }
}#triangle1{
    position:absolute;
    top: 15%;
    left: 50%;
    width: 5rem;
    height: 2rem;
    border-bottom: 40px solid transparent;
    border-top: 40px solid #c7c7c7d8;
    border-left: 45px solid transparent;
    border-right: 45px solid transparent;
    z-index: 5;
}