.container-nav{
    width: 100%;
    height: 50px;
    padding: 20px;
    position: fixed;
    // background-color: rgba(128, 128, 128, 0.24);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    z-index: 500;
    span{
        font-size: 0.7rem;
    }
}
#_레이어_1{
    width: 23.2px;
    height: 23.2px;
    display: flex;
    align-items: center;
    justify-content: center;
    
}.cls-2{
    fill: #ddff00;
}
.cls-1 {
    fill: #5a594f;
    stroke-width: 0px;
}.container-mode{
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    overflow: hidden;
    border:2px solid rgb(129, 129, 129);
    cursor: pointer;
    margin-right: 1rem;
}.frame-mode{
    position: relative;
}.moon{
    width: 33px;
    height: 33px;
    position: absolute;
}.light-moon{
    background-color: #edeee8;
    transform: translate(-50%,-50%);

}.dark-moon{
    background-color: #67665c;
    transform: translate(0%,-50%);
}