$width:18rem;

.container-myBlog{
    background-color:#7b7b7b2b;
    width: 100vw;
    height: 22rem;
    gap: 4rem; 
    // margin-top: 10rem;
    
}
.blog{
        // background-color: black;
        padding: 1rem;
        width: $width;
        height: $width;
        border: 1px solid rgb(172, 172, 172);
        border-radius: 0.4rem;
        position: relative;   
        &>span{
            // margin-bottom: 1.3rem;
        }
        &>li{
            margin-top: 0.5rem;
            font-size: 0.8rem;
            word-spacing:-1px;
            max-width: 100%;
        }
        position: relative;
        // overflow: hidden;
        overflow: hidden;
}
    
.blog-link{
    font-size: 0.8rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: rgb(111, 111, 199);
}
.myBlog-svg{
    gap: 0.5rem;
    margin-left: auto;
    margin-right: auto;
    &>img{
        width: 2.3rem;
    }
    &>span{
        font-size: 1.1rem;
    }
}
.mirror-circle{
    position: absolute;
    width: 4rem;
    height: 4rem;
    opacity: 0.7;
    pointer-events: none;

    border-radius: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: rgb(255, 255, 255);
    user-select: none;
    pointer-events: none;
    mix-blend-mode: difference;
    opacity: 0;
    z-index:1;
}
.null-box-blog{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0%;
    left: 0%;
    z-index: 5;
    overflow: hidden;
    cursor: pointer;
}.blog-in{
    position: absolute;
    z-index: 10;
    width: $width;
    height:$width;
    left: 0;
    padding: 1rem;
    z-index: 1;
    &>span{
        // margin-bottom: 1.3rem;
    }
    &>li{
        margin-top: 0.5rem;
        font-size: 0.8rem;
        word-spacing:-1px;
        max-width: 100%;
    }
    // background-color: black;
}