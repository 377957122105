$border-color:white;

.frame-projectView-line{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.projectView-line{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    &>div:nth-child(1),&>div:nth-child(3){
        width: 2px;
        height: calc( 50% - 24px );
        background-color: rgba(148, 148, 148, 0.377);
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        &>div{
            width: 2px;
            height: 100%;
            background-color: $border-color;
        }
    }
    &>div:nth-child(2){
        width: 48px;
        height: 48px;
        border-radius: 100%;
        background-color: rgba(148, 148, 148, 0.377);
        position: relative;
        overflow: hidden;
        &>div:first-child{
            width: 48px;
            height: 48px;
            position: relative;
            background-color: $border-color;
        }
        &>div:nth-child(2){
                width: 90%;
                height: 90%;
                border-radius: 100%;
                background-color: black;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                font-family: "Noto Sans KR", sans-serif;
                font-weight: 600;
                font-size: 1.2rem;
        }
    }

    
}.projectView-line:first-child{
    &>div:first-child{
        background-color: transparent;
        &>div{
            background-color: transparent;
        }
    }
}.projectView-line:last-child{
    &>div:last-child{
        background-color: transparent;
        &>div{
            background-color: transparent;
        }
    }
    
}