$width-me:720px;
$width-front:240px;

.container-myskilltrees{
    margin-left: auto;
    margin-right: auto;
    position: relative;
    width: 100%;
    height:100vh;
    display: grid;
    grid-template-columns:2fr 1fr 1fr;
    gap: 1rem;
    &>div{
        
    }
}
.green{
    color: rgb(255, 255, 120);
}.blue{
    color: #1a00ff;
}
.container-about-out{
    position: absolute;
}
.container-about-in{
    user-select: none;
    position: relative;
    border: 1px solid rgb(138, 138, 138);
    border-radius: 4px;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    background-color: #2d2d2d9e;
    pointer-events: none;

    &>div:first-child{
        height: 32px;
        // border-bottom: 1px solid rgb(138, 138, 138);
        padding: 1rem;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        &>div{
            &>span{
                width: 1rem;
                height: 1rem;
                display: flex;
                font-size: 1.1rem;
                font-weight: 100;
            }
            &>div{
                width: 1rem;
                height: 1rem;
                background: linear-gradient(200deg, rgb(255, 159, 159),rgb(228, 34, 12),rgb(255, 149, 149));
                border-radius: 100%;
            }
        }
    }
    
}
// ---------------------------------------------------------------------------
// ABOUT ME
.container-aboutme-out{
    width: $width-me*1.05;
    height: calc($width-me*0.275*1.1);
    position: absolute;
    left: 50%;
    top:30%;
    transform: translate(-50%,-50%);
    border-radius: 4px;
    padding: 2rem;
    // background-color: white;
}.container-aboutme-in{
    position: absolute;
    width: $width-me;
    height: $width-me*0.275;
    &>ol{
        padding: 1rem;
        gap:0.5rem;
        font-size: 1.05rem;
        margin-top: 0.5rem;
        &>li{
            display: grid;
            grid-template-columns: 1rem auto;

            &>span{
                text-align: start;
                line-height: 1.2rem;
            }
        }
    }
}

// FRONT

.container-frontend-out{
    width: 250px;
    height: 350px;
    // background-color: white;
    left:50%;
    top:55%;
    transform: translate(80%,-50%);
    z-index: 10;
}
.container-frontend-in{
    width: $width-front;
    height: auto;
    &>ol{
        padding:1rem;
        gap:0.5rem;
        &>li{
            gap:0.3rem;
            &>img{
                width: 1.2rem;
            }
            &>span{
                font-size: 1.2rem;
            }
        }
    }
}

// BACK
.container-backend-out{
    width: 300px;
    height: 200px;
    left:50%;
    top:50%;
    transform: translate(-430px,-50%);
    // background-color: white;
}
.container-backend-in{
    width: 280px;
    height: auto;
    &>ol{
        padding:1rem;
        gap:0.5rem;
        &>li{
            gap:0.3rem;
            &>img{
                width: 1.2rem;
                height:1.2rem;
            }
            &>span{
                font-size: 1.2rem;
            }
        }
    }
}
// VERSION CONTROL
.container-vc-out{
    width: 260px;
    height: 200px;
    left: 50%;
    top:0%;
    transform: translate(-500px,0);
    // background-color: white;
}
.container-vc-in{
    width: 240px;
    height: auto;
    &>ol{
        padding:1rem;
        gap:0.5rem;
        &>li{
            gap:0.3rem;
            &>img{
                width: 1.2rem;
            }
            &>span{
                font-size: 1.2rem;
            }
        }
    }
}

.tree-icon{
    border-radius: 2px;
}