.projectView-info{
    &>div{
        gap: 0.5rem;
        box-sizing: border-box;
        padding: 0.5rem
    }
}
.frame-projectView-info{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    &>div{
        gap: 2rem;
    }
}
.projectView-info-in{
    gap: 0.5rem;
    &>div{
        gap: 0.5rem;
        &>span{
            font-size: 0.9rem;
            text-align: start;
        }
    }
}   

.projectView-info-skill-set{
    width: 100%;
    height: auto;
    gap: 0.2rem;
    &>div{
        gap: 6px;
    }
    &>div>span{
        gap:6px;
        &>img{
            width: 1rem;
            height: 1rem;
        }
    }
}
.projectView-in-title{
    font-size: 1rem;
}
.projectView-info-link{
    width: 100%;
    display: flex;
    gap: 0.2rem;
    flex-direction: column;
    &>div{
        display: flex;
        gap: 0.5rem;
        &>span:nth-child(2){
            color: rgb(113, 113, 153);
            cursor: pointer;
            text-decoration: underline;
            text-align: start;
        }
        
    }
}.projectView-info-date{
    margin-right: auto;
    margin-bottom: 0.5rem;
}.projectButton{
    margin-right: auto;
    border: none;
    background-color: transparent;
    cursor: pointer;
    text-decoration: underline;
    &:hover{
        opacity: 0.8;
    }
}