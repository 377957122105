.fcss{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}.fccs{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}.fcsc{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}.fccc{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.frss{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}.frcs{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}.frsc{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
}.frcc{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}